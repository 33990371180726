import request from '@/common/request'

// 此方法目前没用，使用的假数据
export function loadMajor () {
  return request({
    url: '/am/major',
    method: 'get'
  })
}

// 加载全部博主位置信息
export function loadAnchorman () {
  return request({
    url: '/am/all',
    method: 'get'
  })
}

// 加载博主信息
export function loadAmInfo (id) {
  return request({
    url: `/am/${id}/info`,
    method: 'get'
  })
}

// 通过dyid 或room id查询博主
export function loadAmInfoExtra (id) {
  return request({
    url: `/am/${id}/extra`,
    method: 'get'
  })
}

// 加载伤残等级分布
export function loadScdj () {
  return request({
    url: `/am/als/scdj`,
    method: 'get'
  })
}

// 加载伤残等级分布provinceDistribute
export function loadProvinceDistribute () {
  return request({
    url: `/am/als/pd`,
    method: 'get'
  })
}

// 加载按学历、年龄段人数分布Distribute
export function loadAgeEducationDistribute () {
  return request({
    url: `/am/als/ageXl`,
    method: 'get'
  })
}

// 加载热度排名
export function loadFadTop () {
  return request({
    url: `/am/als/fad/top`,
    method: 'get'
  })
}

// ----
export function fetchAnchormans (filter, sorter, page) {
  return request({
    url: '/am/fetch/list',
    method: 'POST',
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: { 'filter': filter, 'sorter': sorter, 'page': page }
  })
}

export function getDyUser (secId) {
  return request({
    url: '/am/dy/user',
    method: 'get',
    params: {'secId': secId}
  })
}

export function loadAmInfoById (id) {
  return request({
    url: `/am/${id}/load`,
    method: 'get',
    params: {'id': id}
  })
}

export function createAnchorman (amInfo) {
  return request({
    url: '/am/create',
    method: 'POST',
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: amInfo
  })
}

export function updateAnchorman (id, amInfo) {
  return request({
    url: `/am/${id}/edit`,
    method: 'POST',
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: amInfo
  })
}

export function refreshAmInfo (aid, secId) {
  return request({
    url: `/am/dy/user/refresh`,
    method: 'POST',
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: {"aid": aid, "secId": secId}
  })
}
