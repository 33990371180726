import { themeSetting } from '../../../../soybean-admin/src/settings/theme';
<template>
  <div>
    <el-dialog :visible.sync="dialogVisible" width="1024px" :destroy-on-close="true" :modal="false" 
    :close-on-click-modal ="false"
    @closed="onDismiss"
    class="live_dialog"
    >
      <div id="live_container" style="width:100%;height:640px;position: relative;">
        <div v-if="loading" v-loading="loading" style="position:absolute;top:50%;left: calc(50% - 32px);width: 64px;" element-loading-background="rgba(255, 255,255, 0.9)"
          element-loading-text="加载中..."/>
      </div>
      <el-divider />
    
      <div style="text-align:center;">
        <el-button type="primary" @click="openDy">
          全屏互动
          <!--a href="javascript:void(0)"  style="text-decoration: none">
            
          </a-->
        </el-button>
        <el-button type="success" @click="dismiss">
          关 闭
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { open } from '../../utils/dialog'

export default {
  data() {
    return {
      dialogVisible: false,
      loading: false,
      rid: null
    };
  },
  components: {

  },
  props: {
    // rid: {//抖音room id
    //   type: String,
    //   default: ''
    // },
  },
  watch: {
    // show(newVal) {
    //   this.dialogVisible = newVal
    // }
  },
  methods: {
    show(rid) {
      if (!rid || rid == '') {
        this.$alert("未找到当前博主直播间！")
        return
      }

      this.rid = rid
      this.dialogVisible = true
      this.loading = true
      
      setTimeout(() => {
        this.injectContent(rid)
      }, 100);
    },
    injectContent(rid) {
      const t = this
      window.addEventListener('message', (e)=>{
        if(e['data']['type'] == 'cntDown'){
          t.loading = false
        }
      }, false)

      var iframe = document.createElement('iframe');
      iframe.setAttribute("frameborder", 0)
      iframe.setAttribute("width", "100%")
      iframe.setAttribute("height", "100%")
      document.getElementById("live_container").appendChild(iframe);
      iframe.src = "/dylive.html?rid=" + rid
      //iframe.contentWindow.document.close();
      // setTimeout(() => {
      //   this.loading = false
      // }, 2000);
    },
    dismiss() {
      this.dialogVisible = false
    },
    onDismiss() {
      this.dialogVisible = false
    },
    openDy() {
      this.dismiss()
      const url = 'https://live.douyin.com/' + this.rid
      open(url)
    }
  }
}  
</script>
<style lang="scss"  >
.live_dialog {
  .el-dialog {
    border-radius: 20px !important;
    background-color: rgba(25, 25, 25, 0.98);
    box-shadow: 0 2px 6px rgb(250 250 250 / 20%);
    
      .el-dialog__header {
        display: none !important;
      }
    }
}
</style>