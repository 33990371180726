<template>
    <div id="index" ref="appRef">
        <div class="bg">
            <dv-loading v-if="loading">加载中 ...</dv-loading>
            <div v-else class="host-body">
                <div class="d-flex jc-center">
                    <dv-decoration-10 class="dv-dec-10"/>
                    <div class="d-flex jc-center">
                        <dv-decoration-8 class="dv-dec-8" :color="decorationColor"/>
                        <div class="title">
                            <span class="title-text">直播云管理平台</span>
                            <dv-decoration-6
                                    class="dv-dec-6"
                                    :reverse="true"
                                    :color="['#50e3c2', '#67a1e5']"
                            />
                        </div>
                        <dv-decoration-8
                                class="dv-dec-8"
                                :reverse="true"
                                :color="decorationColor"
                        />
                    </div>
                    <dv-decoration-10 class="dv-dec-10-s"/>
                </div>

                <!-- 第二行 -->
                <div class="d-flex jc-between px-2">
                    <div class="d-flex aside-width">
                        <div class="react-left ml-4 react-l-s">
                            <span class="react-left"></span>
                            <div class="text" style="font-weight: bolder;display: flex;">
                                <router-link to="/am/mg" :class="{'disabled' : !isManager}">
                                    <i class="el-icon-data-board" /> 博主管理
                                </router-link>
                                <span style="padding-left: 20px" />
                                <router-link :to="{ path: '/sys/user' }" :class="{ 'disabled': !isAdmin}">
                                <i class="el-icon-setting"/> 系统设置
                                </router-link>
                                
                                <span style="padding-left: 20px"/>
                                <a @click="mapSetup">
                                    <i class="el-icon-picture"/> 地图设置
                                </a>
                            </div>
                        </div>
                        <div class="react-left ml-3">
                            <span class="text  fw-b">主播总数：{{ am_total }} 人</span>
                        </div>
                    </div>
                    <div class="d-flex aside-width">
                        <div class="react-right bg-color-black mr-3">
                            <span class="text fw-b">正在直播：{{ am_live }} 人</span>
                        </div>
                        <div class="react-right mr-4 react-l-s">
                            <span class="react-after"></span>
                            <span class="text mr-30 user_name"><i class="el-icon-user-solid"></i> {{userName}}</span>
                            <span class="text"
                            >{{ dateYear }} {{ dateWeek }} {{ dateDay }}</span
                            >
                        </div>
                    </div>
                </div>

                <div class="body-box">
                    <!-- 第三行数据 -->
                    <div class="content-box">
                        <div>
                            <div>
                                <dv-border-box-12 style="height:460px">
                                    <centerLeft1/>
                                </dv-border-box-12>
                            </div>
                            <div>
                                <dv-border-box-12 style="height:480px">
                                    <centerLeft2/>
                                </dv-border-box-12>
                            </div>
                        </div>
                        <!-- 中间 -->
                        <div>
                            <center ref="center"/>
                        </div>
                        <!-- 中间 -->

                        <div>
                            <dv-border-box-13 style="height:auto">
                                <centerRight1 @showAnchor="showAnchor"/>
                            </dv-border-box-13>
                            <dv-border-box-13 style="height:auto">
                                <centerRight2/>
                            </dv-border-box-13>
                        </div>
                    </div>

                    <!-- 第四行数据 -->
                    <!--div class="bottom-box">
                      <dv-border-box-13>
                        <bottomLeft />
                      </dv-border-box-13>
                      <dv-border-box-12>
                        <bottomRight />
                      </dv-border-box-12>
                    </div-->

                </div>
            </div>

        </div>
        <mapSetup ref="map_setup" />
    </div>
</template>

<script>
import drawMixin from "../utils/drawMixin";
import {formatTime} from '../utils/index.js'
import centerLeft1 from './centerLeft1'
import centerLeft2 from './centerLeft2'
import centerRight1 from './centerRight1'
import centerRight2 from './centerRight2'
import center from './center'
import mapSetup from '_c/mapSetup'

//import bottomLeft from './bottomLeft'
//import bottomRight from './bottomRight'

import {loadMajor} from '@/api/anchorman'
import {Message} from "element-ui";

export default {
    mixins: [drawMixin],
    data() {
        return {
            timing: null,
            loading: true,
            dateDay: null,
            dateYear: null,
            dateWeek: null,
            weekday: ['周日', '周一', '周二', '周三', '周四', '周五', '周六'],
            decorationColor: ['#568aea', '#000000'],
            data: {},
            am_total: 1316,
            am_live: 298
        }
    },
    components: {
        centerLeft1,
        centerLeft2,
        centerRight1,
        centerRight2,
        center,
        mapSetup
        //bottomLeft,
        //bottomRight
    },
    computed:{
        userName(){
            return this.$store.getters['user/name']
        },
        roles(){
            return this.$store.getters['user/roles']
        },
        isManager(){
            return this.checkPermit('manager')
        },
        isAdmin() {
            return this.checkPermit('admin')
        }
    },
    watch:{
        '$store.getters.am_total': function (total) {
            // 改用固定值
            this.am_total = parseInt(total['total'])
// console.log('watched: $store.getters.am_total1 '+total)
            this.am_live = 0 + parseInt(total['live'])
        },
    },
    mounted() {
        this.timeFn()
        this.cancelLoading()

        this.am_total = 0 + parseInt(this.$store.getters.am_total['total'])
        this.am_live = 0 + parseInt(this.$store.getters.am_total['live'])
        //this.initWs()
        //this.loadMajor()
        //const i = 6000 * 5
        //setInterval(() => { this.loadMajor() }, i)

    },
    beforeDestroy() {
        clearInterval(this.timing)
        //this.$io.off('total:update')
    },
    methods: {
        checkPermit(a){
            return this.roles.indexOf(a) != -1
        },
        async mapSetup() {
            const mi = this.$refs.center.getMapInfo()//.$emit("mapSetup")不生效
            
            this._.extend(this.$store.getters.map_conf, mi)
            this.$refs.map_setup.show(this.$store.getters.map_conf)
//        window.fetch('/api/rcv/t', {
//        method: 'POST',
//        headers: {
//        'Content-Type': 'application/json'
//        },
//        body: JSON.stringify({total:12,live:32})
//        }).then(resp => {
//        console.log('response:', resp.text())
//        })
        },
        async initWs() {
            // watch total update
            // result:{total:1234, live:123}
            this.$io.on('total:update', result => {
                if (window['__debug__']) {
                    console.log('update total:', result)
                }

                // 以下两行为原页面中代码
                // this.am_total = 700 + parseInt(result['total'])
                // this.am_live = 0 + parseInt(result['live'])
                // 改为
                //result['live'] = 298 //用于手工修改在线人数
                this.$store.dispatch('update_total', result)
            })
            setTimeout(() => {
                this.$io.emit('fetch-once')
            }, 200);
        },
        async loadMajor() {
            const rlt = await loadMajor()
            if (rlt['status'] != -1) {
                Message(`加载数据错误，稍后按F5键刷新重试！${rlt['err']}`, {
                    type: 'error'
                })
                return
            }

            this.data = rlt['content']
        },
        timeFn() {
            this.timing = setInterval(() => {
                this.dateDay = formatTime(new Date(), 'HH: mm: ss')
                this.dateYear = formatTime(new Date(), 'yyyy-MM-dd')
                this.dateWeek = this.weekday[new Date().getDay()]
            }, 1000)
        },
        cancelLoading() {
            setTimeout(() => {
                this.loading = false
            }, 500)
        },
        showAnchor(id) {
            this.$refs.center.showAmInfo({'dyid': id})
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/index.scss';

.text-icon {
  background-color: white;
}
.mr-30{
    margin-right: 30px;
}
.user_name{
    color:#3CA776;
    font-size: 1.6rem;
    font-weight: bolder;
}
a.disabled {
    pointer-events: none;
    color: #4c4c58;
}
</style>
