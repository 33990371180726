import amInfo from '_c/amInfo';
<template>
  <div class="conf_dialog">
    <el-dialog v-if="dialogVisible" :visible.sync="dialogVisible" width="480px"
      title="设置地图参数" :before-close="handleClose" :destroy-on-close="true" :modal="false"
      @closed="onDismiss">
      <el-form :model="map_conf" label-width="110px">
        <label style="font-weight:bolder">地图中心点：</label>
        <el-form-item label="经度">
          <el-input-number v-model="map_conf.center.lng" :precision="2" :step="0.1" :max="180"></el-input-number>
        </el-form-item>
        <el-form-item label="纬度">
          <el-input-number v-model="map_conf.center.lat" :precision="2" :step="0.1" :max="90"></el-input-number>
        </el-form-item>
        <el-divider />

        <el-form-item label="俯仰角">
          <el-input-number v-model="map_conf.pitch" :precision="1" :step="1" :max="180"></el-input-number>
        </el-form-item>
        <el-form-item label="缩放级别">
          <el-input-number v-model="map_conf.zoom" :step="1" :max="30"></el-input-number>
        </el-form-item>
        <el-form-item label="旋转角度">
          <el-input-number v-model="map_conf.rotation" :step="1" :max="360"></el-input-number>
        </el-form-item>
        <el-form-item label="视图模式">
          <el-select v-model="map_conf.viewMode" placeholder="请选择">
            <el-option v-for="item in viewMode" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="底图类型">
          <el-select v-model="map_conf.baseMap" placeholder="请选择">
            <el-option v-for="item in baseMap" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="矢量图样式">
          <el-select v-model="map_conf.styleId" placeholder="请选择">
            <el-option v-for="item in mapStyle" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="不显示直播状态">
          <el-switch v-model="map_conf.hideLive" />
        </el-form-item>

      </el-form>
    
      <div v-if="loading" v-loading="loading" style="position:absolute;top:50%;left: calc(50% - 32px);width: 64px;"
        element-loading-background="rgba(255, 255,255, 0.9)" element-loading-text="加载中..." />
      <el-divider />
      <div style="text-align:right;">
        <el-button type="success" @click="save">
          保存设置
        </el-button>
        <el-button type="default" @click="dismiss">
          关闭
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>

import { saveConf } from '@/api/sys';

export default {
  data() {
    return {
      dialogVisible: false,
      loading: false,
      map_conf: {},
      viewMode: [{label:'2D', value:'2D'},{label:'3D', value: '3D'}],
      baseMap: [{ label: '矢量图', value: 'vector' }, { label: '卫星图', value: 'satellite'}],
      mapStyle: [{ label: '澹月', value: 'style1' }, { label: '经典', value: 'style2' }, { label: '墨渊', value: 'style3' }],
    };
  },
  filters: {
    fad(v) {
      return (v % 100).toFixed(2)
    },
  },
  computed:{
  },
  watch: {
    '$store.getters.map_conf': function (conf) {
        this.map_conf = conf
    }
  },
  beforeCreate() {
    
  },
  mounted() {
  },
  methods: {
    //{"center":{"lat":25.43197588002793,"lng":101.73899656599349,"height":0},
    //"pitch":43.5,"zoom":5,"rotation":0,"viewMode":"3D","baseMap":{"type":"satellite"}}
    show(mapInfo) {
      this.dialogVisible = true
      this.loading = true
      
      this.map_conf = mapInfo

      setTimeout(() => {
        this.loading = false
      }, 100);
    },
    async save(){
      this.$store.dispatch('save_map_conf', this.map_conf)

      const uid = this.$store.getters['user/id']
      const rlt = await saveConf(uid, 'map_conf', this.map_conf)
      if(rlt.status != -1) {
        this.$message({
          message: `保存地图配置错误，原因：${rlt.msg}。`,
          type: 'error'
        })
      } else {
        this.$message({
          message: '地图配置保存成功！',
          type: 'success'
        })
      }

      this.dismiss()
    },
    dismiss() {
      this.dialogVisible = false
    },
    onDismiss() {
      this.$emit('onRefresh')
      this.dialogVisible = false
    },
    handleClose(/*done*/) {
      // console.log(JSON.stringify(this.origin_conf), JSON.stringify(this.map_conf));
      // if(hash(this.origin_conf) == hash(this.map_conf)){
      //   done()
      //   return
      // }
      
      // this.$confirm('是否保存配置？',{
      //   confirmButtonText: '保存',
      //   cancelButtonText: '不保存',
      //   type: 'warning'
      // })
      //   .then(async () => {
      //     await this.save()
      //     done();
      //   })
      //   .catch(() => { 
      //     done()
      //   });
    }
  }
}  
</script>
<style lang="scss"  >
.conf_dialog {
  .el-dialog {
    border-radius: 20px !important;
    background-color: rgba(25, 25, 25, 0.98);
    box-shadow: 0 2px 6px rgb(250 250 250 / 20%);
    }
}
</style>