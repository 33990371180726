<template>
  <div id="centerRight1">
    <div class="bg-color-black" style="width:288px">
      <div class="d-flex pt-2 pl-2">
        <span>
          <icon name="chart-line" class="text-icon"></icon>
        </span>
        <div class="d-flex">
          <span class="fs-xl text mx-2">博主音浪排行（前十）</span>
        </div>
      </div>
      <div class="d-flex jc-center body-box" >
        <dv-scroll-board class="dv-scr-board" :config="config" @click="boardClick"/>
      </div>
    </div>
  </div>
</template>

<script>
import { loadFadTop } from '../api/anchorman';
import { Message } from 'element-ui'
const gender = ['未知', '男', '女']

export default {
  data() {
    return {
      ranks: [],
      config: {
        header: ['昵称', '.', '音浪'],
        data: [],//['组件10', 'dev-9', "<span  class='colorGrass'>↑76%</span>"]
        rowNum: 10, //表格行数
        headerHeight: 35,
        headerBGC: '#0f1325', //表头
        oddRowBGC: '#0f1325', //奇数行
        evenRowBGC: '#171c33', //偶数行
        index: true,
        columnWidth: [60,300,60, 120],
        align: ['center'],
        waitTime: 99999999
      }
    }
  },
  watch: {
    '$store.getters.am_rank': function (rank) {
      this.initRank(rank)
    },
  },
  async mounted() {
    this.initRank(this.$store.getters.am_rank)
    //this.initWs()
    //await this.loadData()
  },
  methods: {
    boardClick(row) {
      const cur = this.ranks[row.rowIndex]
      const id =cur['displayID']
      //cur['anchor']['avatar']
      this.$emit('showAnchor', id)
    },
    initRank(rank) {
      this.ranks.splice(0, this.ranks.length)

      this.ranks.push(...rank)

      const cd = []
      rank.forEach((i) => {//shortID
        //const a = i['anchor']
        const _i = [i['anchorName'], i['roomStatus'], `<span class='colorGrass'>${i['income'] / 100}</span>`]
        cd.push(_i)
      });

      this.config = Object.assign({}, this.config, { data: cd })
    },
    async initWs() {//初始化websocket，并订阅在线主播数据变化通知
      await this.$io.on('ranks:update', result => {
        if (window['__debug__']) {
           console.log('update ranks:', result);
        }
        this.ranks.splice(0, this.ranks.length)

        this.ranks.push(...result)
        
        const cd = []
        result.forEach((i) => {//shortID
          //const a = i['anchor']
          const _i = [i['anchorName'], i['roomStatus'], `<span class='colorGrass'>${i['income']/100}</span>`]
          cd.push(_i)
        });

        this.config = Object.assign({}, this.config, { data: cd })
      })

      
    },
    async loadData() {//此方法对应：博主热度排行（前十），根据fad值计算，暂时弃用
      const rlt = await loadFadTop()
      if (rlt['status' != -1]) {
        Message(`加载热度排名数据错误，${rlt['err']}}。`)
        return
      }

      const d = rlt['content']
      const cd = []
      d.forEach((i) => {
        const _i = [i['name'], gender[i['gender']], `<span class='colorGrass'>${i['fad']}</span>`]
       cd.push(_i)
      });

      this.config = Object.assign({}, this.config, {data: cd})
    }
  },
}
</script>

<style lang="scss" scoped>
$box-height: 410px;
$box-width: 300px;

#centerRight1 {
  padding: 16px;
  padding-top: 20px;
  height: $box-height;
  width: $box-width;
  border-radius: 5px;

  .bg-color-black {
    height: $box-height - 30px;
    border-radius: 10px;
  }

  .text {
    color: #c3cbde;
  }

  .body-box {
    border-radius: 10px;
    overflow: hidden;

    .dv-scr-board {
      width: 320px;
      height: 340px;
    }
  }
}
</style>
