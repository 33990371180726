<template>
  <div>
    <Echart
      :options="options"
      id="centreLeft1Chart"
      height="410px"
      width="270px"
    ></Echart>
  </div>
</template>

<script>
import Echart from '@/common/echart'
export default {
  data () {
    return {
      options: {},
    };
  },
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Array,
      default: () => ([])
    },
  },
  watch: {
    cdata: {
      handler (newData) {
        this.options = {
          color: [
            "#fb7293",
            "#32c5e9",
            "#ff9f7f",
            "#ffdb5c",
            "#37a2da",
            "#e7bcf3",
            "#8378ea"
          ],
          tooltip: {
            trigger: 'item',
            formatter: "{a} <br/>{b} : 占比 {d}%"//"{a} <br/>{b} : {c} ({d}%)"
          },
          legend: {
            //top: 0,
            bottom: 10,
            left: 'center'
          },
          label: {
            show: true,
            color: '#fff',
            fontSize: '14px',
            //fontWeight: 'bold',
          },
          itemStyle: {
            borderRadius: 10,
            shadowBlur: 2,
            shadowColor: '#fff',
          },
          series: [
            {
              name: '伤残等级分布',
              type: 'pie',
              radius: ['35%', '70%'],
              center: ["50%", "40%"],
              avoidLabelOverlap: false,
              itemStyle: {
                borderRadius: 10,
                borderColor: '#fff',
                shadowColor: '#fff',
                borderWidth: 1
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: '15px',
                  fontWeight: 'bold'
                }
              },
              labelLine: {
                show: true
              },
              data: newData
            }
          ]
        }

        this.options1 = {
          color: [
            "#fb7293",
            "#32c5e9",
            "#ff9f7f",
            "#ffdb5c",
            "#37a2da",
            "#e7bcf3",
            "#8378ea"
          ],
          tooltip: {
            trigger: "item",
            formatter: "{a} <br/>{b} : {c} ({d}%)"
          },
          // toolbox: {
          //   show: true
          // },
          // calculable: true,
          legend: {
            orient: "horizontal",
            //icon: "circle",
            bottom: 0,
            x: "center",
            textStyle: {
              color: "#fff"
            }
          },
          series: [
            {
              name: "伤残等级分布",
              type: "pie",
              radius: [10, 70],
              roseType: "area",
              center: ["50%", "40%"],
              itemStyle: {
                borderRadius: 6
              },
              data: newData
            }
          ]
        }
      },
      immediate: true,
      deep: true
    }
  }
};
</script>

<style lang="scss" scoped>
</style>