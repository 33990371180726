<template>
  <div class="bg-color-black item1" style="width: 100%;height: 99.5%;">
    <tMap ref="map" @showAmInfo="showAmInfo" />

    <amInfo ref="amInfo" @showLive="showLive"/>
    <dyLive ref="liveDialog" />
  </div>
</template>

<script>
import tMap from "_c/tMap/tMap"
import dyLive from '_c/dyLive'
import amInfo from '_c/amInfo'
import { loadConf } from '@/api/sys'

export default {
  data() {
    return {

    }
  },
  components: {
    tMap,
    dyLive,
    amInfo
  },
  mounted() {
    this.fetchConf().then()
  },
  methods: {
    //设置地图参数，调用api：/api/map/setup
    //{"center":{"lat":25.43197588002793,"lng":101.73899656599349,"height":0},"pitch":43.5,"zoom":5,"rotation":0,"viewMode":"3D","baseMap":{"type":"satellite"}}
    getMapInfo() {
      return this.$refs.map.getMapInfo()
    },
    //接受地图点击标点事件并显示博主信息对话框
    async showAmInfo(amInfo) {
      const dialog = this.$refs.amInfo
      dialog.show(amInfo)
    },
    showLive(lid) {
      this.$refs.liveDialog.show(lid)
    },
    async fetchConf() {
      const rlt = await loadConf(this.$store.getters['user/id'], 'map_conf')
      if (rlt.status != -1) {
        this.$message(`${rlt.msg}`)
        return
      }

      this.$store.dispatch('save_map_conf', rlt.content['conf'])
    }
  }
}
</script>

<style lang="scss" scoped>
#center {
  display: flex;
  flex-direction: column;

  .up {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    .item {
      border-radius: 6px;
      padding-top: 8px;
      margin-top: 8px;
      width: 32%;
      height: 70px;

      .dv-dig-flop {
        width: 150px;
        height: 30px;
      }
    }
  }

  .down {
    padding: 6px 4px;
    padding-bottom: 0;
    width: 100%;
    display: flex;
    height: 255px;
    justify-content: space-between;

    .bg-color-black {
      border-radius: 5px;
    }

    .ranking {
      padding: 10px;
      width: 59%;

      .dv-scr-rank-board {
        height: 225px;
      }
    }

    .percent {
      width: 40%;
      display: flex;
      flex-wrap: wrap;

      .item {
        width: 50%;
        height: 120px;

        span {
          margin-top: 8px;
          font-size: 14px;
          display: flex;
          justify-content: center;
        }
      }

      .water {
        width: 100%;

        .dv-wa-le-po {
          height: 120px;
        }
      }
    }
  }
}
</style>
