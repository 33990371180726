<template>
  <div id="centerLeft1" >
    <div class="bg-color-black"  style="height:430px">
      <div class="d-flex pt-2 pl-2">
        <span>
          <icon name="chart-bar" class="text-icon"></icon>
        </span>
        <div class="d-flex">
          <span class="fs-xl text mx-2">伤残等级分布</span>
          <!--dv-decoration-3 class="dv-dec-3" /-->
        </div>
      </div>
      <div class="d-flex jc-center">
        <scdjChart :cdata="cdata" />
      </div>
      <!-- 4个主要的数据 -->
      <!-- <div class="bottom-data" style="margin-left:5px;">
        <div
          class="item-box mt-2"
          v-for="(item, index) in numberData"
          :key="index"
        >
          <div class="d-flex">
            <span class="coin">♿</span>
            <dv-digital-flop class="dv-digital-flop" :config="item.number" />
          </div>
          <p class="text" style="text-align: center;">
            {{ item.text }}
            <span class="colorYellow">(人)</span>
          </p>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>

import scdjChart from '@/components/scdjChart'
import { loadScdj } from '../api/anchorman'
import { Message } from 'element-ui'
export default {
  data() {
    return {
      cdata: [],
      numberData: []
    }
  },
  components: {
    scdjChart
  },
  async mounted() {
    await this.loadData()
  },
  methods: {
    async loadData() {
      const rlt = await loadScdj()
      if (rlt['status' != -1]) {
        Message(`加载残疾等级分布数据错误，${rlt['err']}}。`)
        return
      }

      const d = rlt['content']
      d.forEach((i, idx) => {
        this.cdata.push({ value: parseInt(i['total']), name: i['cjdj'] + '级' })
        
        if(idx > 3) return
        this.numberData.push({
          number: {
            number: [parseInt(i['total'])],
            toFixed: 0,
            textAlign: 'left',
            content: '{nt}',
            style: {
              fontSize: 24
            }
          },
          text: i['cjdj'] + '级'
        })
      });
    }
  }
}
</script>

<style lang="scss" scoped>
$box-width: '100%';
$box-height: 410px;

#centerLeft1 {
  padding: 16px;
  height: $box-height;
  width: $box-width;
  border-radius: 10px;
  .bg-color-black {
    
    border-radius: 10px;
  }
  .text {
    color: #c3cbde;
  }
  .dv-dec-3 {
    position: relative;
    width: 100px;
    height: 20px;
    top: -3px;
  }

  .bottom-data {

    .item-box {
      & > div {
        padding-right: 5px;
      }
      font-size: 14px;
      float: right;
      position: relative;
      width: 50%;
      color: #d3d6dd;
      .dv-digital-flop {
        width: 120px;
        height: 30px;
      }
      // 金币
      .coin {
        position: relative;
        top: 6px;
        font-size: 20px;
        color: #ffc107;
        margin-right: 10px;
      }
      .colorYellow {
        color: yellowgreen;
      }
      p {
        text-align: center;
      }
    }
  }
}
</style>
