import request from '@/common/request'
import hash from 'js-hash-code'

export function login (uid, pwd) {
  const pwdh = hash(pwd, 'sha1')
  return request({
    url: '/sys/login',
    method: 'get',
    params: { uid: uid, pwd: pwdh }
  })
}

export function fetchUser (uid) {
  return request({
    url: `/sys/user/${uid}/get`,
    method: 'get'
  })
}

export function userExist (uid) {
  return request({
    url: `/sys/user/${uid}/exist`,
    method: 'get'
  })
}

export var createUser = function (user) {
  return request({
    url: '/sys/user/create',
    method: 'POST',
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: user
  })
}

export var updateUser = function (user) {
  return request({
    url: '/sys/user/update',
    method: 'POST',
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: user
  })
}

export var delUser = function (id) {
  return request({
    url: `/sys/user/${id}/del`,
    method: 'DELETE',
    dataType: 'json'
  })
}

export var loadUsers = function (filter, sort, page) {
  return request({
    url: '/sys/user/list',
    method: 'POST',
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: {
      filter: filter,
      sort: sort,
      page: page
    }
  })
}

export function loadConf (uid, key) {
  return request({
    url: `/sys/${uid}/conf/${key}`,
    method: 'get'
  })
}

export function saveConf (uid, key, conf) {
  return request({
    url: `/sys/${uid}/conf/${key}`,
    method: 'POST',
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: conf
  })
}

export default {
  login,
  loadUsers,
  fetchUser,
  userExist,
  delUser,
  loadConf,
saveConf}
