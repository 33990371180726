<template>
  <div id="centerLeft1">
    <div class="bg-color-black" style="height:450px">
      <div class="d-flex pt-2 pl-2">
        <span>
          <icon name="chart-pie" class="text-icon"></icon>
        </span>
        <div class="d-flex">
          <span class="fs-xl text mx-2">各省主播人数分布</span>
          <!--dv-decoration-1 class="dv-dec-1" /-->
        </div>
      </div>
      <div class="d-flex1 jc-center">
        <PdChart :data="data"/>
      </div>
    </div>
  </div>
</template>

<script>
import PdChart from "@/components/provinceDist1";
import { loadProvinceDistribute } from '../api/anchorman'
import { Message } from 'element-ui'

export default {
  data() {
    return {
      data: []
    }
  },
  components: {
    PdChart
  },
  mounted() {
    this.loadData()
  },
  methods: {
    async loadData() {
      const rlt = await loadProvinceDistribute()
      if (rlt['status' != -1]) {
        Message(`加载各省人员分布数据错误，${rlt['err']}}。`)
        return
      }

      const d = rlt['content']
      d.forEach(i => {
        this.data.push({ value: parseInt(i['total']), name: i['province'] })
      })
    }
  }
};
</script>

<style lang="scss" scoped>
#centerLeft1 {
  $box-width: 300px;
  $box-height: 410px;
  padding: 16px;
  height: $box-height;
  min-width: $box-width;
  border-radius: 5px;
  .bg-color-black {
    height: $box-height - 30px;
    border-radius: 10px;
  }
  .text {
    color: #c3cbde;
  }
  .dv-dec-1 {
    position: relative;
    width: 100px;
    height: 20px;
    top: -3px;
  }
  .chart-box {
    margin-top: 16px;
    width: 170px;
    height: 170px;
    .active-ring-name {
      padding-top: 10px;
    }
  }
}
</style>