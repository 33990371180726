<template>
  <div>
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span>
          <icon name="align-left" class="text-icon"></icon>
        </span>
        <span class="fs-xl text mx-2">学历/年龄段人数分布</span>
      </div>
      <div class="d-flex ai-center flex-column body-box">
        <stackedAreaChart :cdata="data && data" height="480px"/>
      </div>
    </div>
  </div>
</template>

<script>
import stackedAreaChart from '@/components/stackedAreaChart'
import { loadAgeEducationDistribute } from '../api/anchorman'
import { Message } from 'element-ui'
const xl_list = ['其它', '初中', '高中', '职高', '中专', '大专', '本科']
const age_range = ['18~24', '25~30', '31~40', '41~49', '其它']

export default {
  data() {
    return {
      data: [xl_list, age_range,[[]]]
    }
  },
  components: { 
    stackedAreaChart 
  },
  async mounted() {
    await this.loadData()
  },
  methods: {
    async loadData() {
      const rlt = await loadAgeEducationDistribute()
      if (rlt['status' != -1]) {
        Message(`加载残疾等级分布数据错误，${rlt['err']}}。`)
        return
      }

      const d = rlt['content']//, s = [[0, 0, 0, 0, 0, 0, 0], [0, 0, 0, 0, 0, 0, 0], [0, 0, 0, 0, 0, 0, 0], [0, 0, 0, 0, 0, 0, 0], [0, 0, 0, 0, 0, 0, 0]]
      
      for(let i = 0; i<5;i++){
        this.data[2].push([0, 0, 0, 0, 0, 0, 0])
      }
      d.forEach(i => {
        let si = age_range.indexOf(i['age'])
        si = si != -1 ? si : 4

        let xi = xl_list.indexOf(i['xl'])
        xi = xi != -1?xi:7

        //s[si][xi] = parseInt(i['total'])
        this.data[2][si][xi] = parseInt(i['total'])
      });

      //this.data[2] = s
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
