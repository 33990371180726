import amInfo from '_c/amInfo';
<template>
  <div>
    <el-dialog v-if="amInfo && dialogVisible" :visible.sync="dialogVisible" width="640px"
      :title="'主播：' + (amInfo ? amInfo['nickname'] : '...')" :destroy-on-close="true" :modal="false"
      @closed="onDismiss">
      <div style="display: flex;justify-content:flex-start;">
        <div>
          <el-avatar shape="square" :size="120" fit="fill" :src="amInfo ? amInfo.avatar : ''" />
        </div>
        <div style="margin-left: 20px;display: flex;flex-direction: column;justify-content: flex-start;">
          <p style="font-weight: bolder;font-size: 24px">
            {{ amInfo['name'] }}（{{ amInfo['gender'] | gender }}
            {{ amInfo['birthday'] | age }}）</p>
          <p style="margin-top:10px">
            <b>{{ amInfo['rzsj'] | date }} 入职</b>，
            {{ amInfo['province'] }}，
            {{ amInfo['mz'] | replenishMz }}，
            {{ amInfo['hyzt'] }}，
            {{ amInfo['cjlb'] }} {{ amInfo['cjdj'] }}级
          </p>
          <p style="margin-top: 10px">
            {{ amInfo['signature'] }}</p>
    
        </div>
    
      </div>
      <div style="margin-top: 10px;width:100%;font-size: 1.2rem;display:flex;justify-content: center;">
        <span style="margin-right:10px">关注 <b>{{ amInfo['following_count'] }}</b></span>
        <span style="margin-right:10px">粉丝 <b>{{ amInfo['follower_count'] }}</b></span>
        <span style="margin-right:10px">获赞 <b>{{ amInfo['total_favorited'] }}</b></span>
        <span style="margin-right:10px">作品数 <b>{{ amInfo['aweme_count'] }}</b></span>
      </div>
      <div v-if="loading" v-loading="loading" style="position:absolute;top:50%;left: calc(50% - 32px);width: 64px;"
        element-loading-background="rgba(255, 255,255, 0.9)" element-loading-text="加载中..." />
      <el-divider />
      <div style="text-align:right" v-if="false || (amInfo['status'] == 0)">
        <el-button type="primary" @click="showAmHome">
          查看作品
        </el-button>
        <el-button :type="liveStatus" :disabled="dyid | living" @click="showLive">
          {{ dyid | liveText }}
        </el-button>
        <el-button type="default" @click="dismiss">
          关闭
        </el-button>
      </div>
      <div style="text-align:right;" v-else>
        <el-tag type="info">用户离线</el-tag>
        <el-button type="default" @click="dismiss">
          关闭
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import dayjs from 'dayjs'
import { loadAmInfo, loadAmInfoExtra, refreshAmInfo } from '@/api/anchorman'
import {open} from '../../utils/dialog'

const genderMap = ['未知', '女', '男']
let that
export default {
  data() {
    return {
      dialogVisible: false,
      loading: false,
      amInfo: {},
      liverIds: []
    };
  },
  filters: {
    fad(v) {
      return (v % 100).toFixed(2)
    },
    gender(v) {
      return genderMap[v]
    },
    age(v) {

      return dayjs().diff(new Date(v), 'year')
    },
    date(v) {
      return dayjs(v).format('YY年M月D日')
    },
    replenishMz(v) {
      return v && v.indexOf('族') === -1 ? v + '族' : v
    },
    living(v) {
      return !that.liverIds.includes(v)
    },
    liveText(v) {
      return that.liverIds.includes(v) ? '正在直播' : '用户离线'
    },
    
  },
  computed:{
    dyid() {
      return this.amInfo['dyid']
    },
    liveStatus() {
      const ids = this.$store.getters.am_live_ids
      //console.log('----', this.dyid);
      return ids.includes(this.dyid) ? 'success' : 'default'
    }
  },
  props: {
    // rid: {//抖音room id
    //   type: String,
    //   default: ''
    // },
  },
  watch: {
    // '$store.getters.am_live': function (a) {
    //     this.livers = a
    // },
    '$store.getters.am_live_ids': function (a) {
        //console.log('----$store.getters.am_live_ids change',a);
        this.liverIds = a
    }
  },
  beforeCreate() {
    that = this
  },
  mounted() {
    this.liverIds = this.$store.getters.am_live_ids
  },
  methods: {
    async show(amInfo) {
      if (!amInfo || amInfo == '') {
        this.$alert("未找到当前博主信息！")
        return
      }

      this.amInfo = amInfo
      this.dialogVisible = true
      this.loading = true
      
      if(amInfo['id']){
        await this.showAmInfo(amInfo);
      }

      if(amInfo['dyid'] || amInfo['dy_room_id']) {
        await this.showAmInfoByDyId(amInfo['dyid']||amInfo['dy_room_id'])
      }

      const a = this.amInfo
      if (!(a.id && a.dysecid)){
        return
      }

      const ud = await refreshAmInfo(a.id, a.dysecid)
      
      if(ud.status != -1) {
        console.log('Refreshing am info failurd! %s', ud.msg);
        return
      }

      this._.extend(this.amInfo, ud.content)
      if(this.amInfo['live_status'] == 0){
        let i = this.liverIds.indexOf(this.amInfo['dyid'])
        i != -1 && this.liverIds.splice(i, 1)
      }
      if (this.amInfo['live_status'] == 1) {
        this.liverIds.push(this.amInfo['dyid'])
      }
      console.log("----",this.liverIds, this.amInfo);
    },
    //接受地图点击标点事件并显示博主信息对话框
    async showAmInfo(amInfo) {
      const rlt = await loadAmInfo(amInfo['id'])
      this.loading = false
      if (rlt['err']) {
        this.$message(`加载博主数据错误，稍后重试！${rlt['err']}`, {
          type: 'error'
        })
        this.dismiss()
        return
      }

      //几乎不会发生
      if (rlt['total'] == 0) {
        this.$message(`当前博主信息不存在！`)
        this.dismiss()
        return
      }

      this.amInfo = rlt['content']
    },
    //id can be dyid or dy_live_id
    async showAmInfoByDyId(id) {
      const rlt = await loadAmInfoExtra(id)
      this.loading=false
      if (rlt['err']) {
        this.$message(`加载博主数据错误，稍后重试！${rlt['err']}`, {
          type: 'error'
        })
        this.dismiss()
        return
      }
      //音浪排行中大部分数据不在维护列表中
      if(rlt['total'] == 0) {
        this.$message(`当前博主信息不在维护列表中！`)
        this.dismiss()
        return
      }

      this.amInfo = rlt['content']
    },
    dismiss() {
      this.dialogVisible = false
    },
    onDismiss() {
      this.dialogVisible = false
    },
    showAmHome(){
      const url = 'https://douyin.com/user/' + this.amInfo['dysecid']
      open(url)
    },
    showLive() {
      const dlId = this.amInfo['dy_live_id']
      if (!dlId || dlId == '') {
        this.$alert("未找到当前博主直播间！")
        return
      }

      this.$emit('showLive', dlId)
    }
  }
}  
</script>
<style lang="scss"  >
.live_dialog {
  .el-dialog {
    border-radius: 20px !important;
    background-color: rgba(25, 25, 25, 0.98);
    box-shadow: 0 2px 6px rgb(250 250 250 / 20%);
    
      .el-dialog__header {
        display: none !important;
      }
    }
}
</style>