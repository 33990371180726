<template>
  <div>
    <Echart
      :options="options"
      id="chartProvince"
      height="480px"
      width="320px"
    ></Echart>
  </div>
</template>

<script>
import Echart from '@/common/echart'
export default {
  data () {
    return {
      options: {},
    };
  },
  components: {
    Echart,
  },
  props: {
    data: {
      type: Array,
      default: () => ([])
    },
  },
  watch: {
    data: {
      handler (newData) {
        this.options2 = {
          color: [
            "#fb7293",
            "#32c5e9",
            "#ff9f7f",
            "#ffdb5c",
            "#37a2da",
            "#e7bcf3",
            "#8378ea"
          ],
          tooltip: {
            trigger: 'item'
          },
          legend: {
            top: '5%',
            left: 'center'
          },
          series: [
            {
              name: 'Access From',
              type: 'pie',
              radius: ['40%', '70%'],
              avoidLabelOverlap: false,
              itemStyle: {
                borderRadius: 10,
                borderColor: '#fff',
                borderWidth: 2
              },
              label: {
                show: false,
                position: 'center'
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: '40',
                  fontWeight: 'bold'
                }
              },
              labelLine: {
                show: false
              },
              data: newData
            }
          ]
        }
        this.options = {
          color: [
            "#fb7293",
            "#32c5e9",
            "#ff9f7f",
            "#ffdb5c",
            "#37a2da",
            "#e7bcf3",
            "#8378ea"
          ],
          tooltip: {
            trigger: "item",
            formatter: "{a} <br/>{b} : 占比 {d}%"//
          },
          toolbox: {
            show: true
          },
          calculable: true,
          legend: {
            orient: "horizontal",
            icon: "circle",
            bottom: 60,
            //top: '5%',
            x: "center",
            textStyle: {
              color: "#fff"
            }
          },
          label: {
            color: '#fff',
            fontSize: '14px',
            fontWeight: 'bold'
          },
          itemStyle: {
            borderRadius: 6,
            shadowBlur: 2,
            shadowColor: '#fff',
          },
          series: [
            {
              name: "各省人员分布",
              type: "pie",
              radius: [40, 80],
              roseType: "area",
              center: ["50%", "35%"],
              emphasis: {
                label: {
                  show: true,
                  fontSize: '15px',
                  fontWeight: 'bold'
                }
              },
              // itemStyle: {
              //   borderRadius: 6
              // },
              data: newData
            }
          ]
        }
      },
      immediate: true,
      deep: true
    }
  }
};
</script>

<style lang="scss" scoped>
</style>