import _ from 'underscore'

export function open (url, option = {}) {
  var _o = {
    width: window.screen.availWidth * 0.9,
    height: window.screen.availHeight * 0.9,
    left: window.screen.availWidth * 0.1 / 2,
    top: window.screen.availHeight * 0.1 / 2,
    toolbar: 'no',
    menubar: 'no',
    scrollbars: 'no',
    status: 'no',
    resizable: 'yes',
    location: 'no',
    alwaysRaised: 'yes', // 指定窗口悬浮在所有窗口之上
    depended: 'yes', // 是否和父窗口同时关闭
    //'z-look': 'yes' // | yes/no | 窗口被激活后是否浮在其它窗口之上
  }

  const o = _.extend(_o, option)
  var compiled = _.template('height=<%= height %>, width=<%= width %>, ' +
    'top=<%= top %>, left=<%= left %>, toolbar=<%= toolbar %>, ' +
    'menubar=<%= menubar %>, scrollbars=<%= scrollbars %>, ' +
    'resizable=<%= resizable %>, location=<%= location %>, status=<%= status %>, ' +
    'alwaysRaised=<%= alwaysRaised %>, depended=<%= depended %>,z-look=yes')
  const attr = compiled(o)
console.log('---d--',attr);
  window.open(url, 'newwindow', attr)
}
