<template>
    <div class="tMap">
        <div ref="tx_map_container" class="tMap"/>
    </div>
</template>

<script>
import {loadAnchorman} from '@/api/anchorman'
import {Message} from 'element-ui'

const TMap = window.TMap
const styles = {
    stl1: new TMap.MarkerStyle({
        width: 64,
        height: 64,
        anchor: {x: 10, y: 30},
        src: '/marker/mark_r.png'
    }),
    stl2: new TMap.MarkerStyle({
        width: 64,
        height: 64,
        anchor: {x: 12, y: 32},
        src: '/marker/mark_b.png'
    }),
    stl1l: new TMap.MarkerStyle({
        width: 64,
        height: 64,
        anchor: { x: 10, y: 30 },
        src: '/marker/mark_r1.png'
    }),
    stl2l: new TMap.MarkerStyle({
        width: 64,
        height: 64,
        anchor: { x: 12, y: 32 },
        src: '/marker/mark_b1.png'
    })
}

export default {
    name: 'tMap',
    props: {},
    data() {
        return {
            ams: [],//所有博主信息列表,用于显示博主信息时能够显示主要信息
            map: null,
            map_conf: {},
            label: null,
            infoWindow: null,
            currentMarker: null,//当前点中的marker id
            livers: [],//正在直播的人
            liverIds: [],//处理后的在线直播的人的抖音id
        }
    },
    computed: {
        /**
         * 获取地图当前试图参数，格式为：
         * {
         *   "center": {
         *     "lat": 33.560645711695805,
         *     "lng": 103.9982392489087,
         *     "height": 0
         *   },
         *   "pitch": 55.00000000000001,
         *   "zoom": 5.388843010295018,
         *    "rotation": 0,
         *    "viewMode": "3D"
         * }
         * @returns {{center: *, zoom: *, pitch: *}|string}
         */
        mapInfo() {
            if (this.map == null) {
                return false
            }
            const map = this.map
            return {
                center: map.getCenter(),
                pitch: map.getPitch(),
                zoom: map.getZoom(),
                rotation: map.getRotation(),
                styleId: this.map_conf['styleId'],//此方法不存在
                viewMode: map.getViewMode(),
                baseMap: map.getBaseMap()['type']
            }
        },
        
    },
    watch: {
        // '$store.getters.am_live': function (a) {
        //     this.livers = a
        // },
        // '$store.getters.am_live_ids': function (a) {
        //     console.log('----$store.getters.am_live_ids change',a);
        //     this.liverIds = a
        // },
        //监控并更新地图配置
        '$store.getters.map_conf': function (conf) {
            this.map_conf = conf
            this.updateMap(conf)
        },
        '$store.getters.am_live': function (lives) {
            console.log("-------watching lives changed", lives)

            this.initLive(lives)
        },
    },
    beforeDestroy() {
        //this.$io.off('live:update')
    },
    async mounted() {
        this.$nextTick(async () => {
            await this.initMap()
            // this.map.setViewMode('3D');
            // this.map.setPitch(50);
            //this.marker()

            await this.loadAnchormans()
            await this.makeMarkers()
        })

        //this.initWs()
        this.initLive(this.$store.getters.am_live)
    },
    methods: {
        //获取当前地图设置参数
        //{"center":{"lat":25.43197588002793,"lng":101.73899656599349,"height":0},"pitch":43.5,"zoom":5,
        //"rotation":0,"viewMode":"3D","baseMap":{"type":"satellite"}}
        getMapInfo() {
            return this.mapInfo
        },
        initLive(data){
            this.livers.splice(0, this.livers.length)
            this.liverIds.splice(0, this.liverIds.length)

            this.livers.push(...data)
            this.liverIds.push(...this._.pluck(this.livers, 'id'))

            this.makeMarkers()
        },
        initWs() {//初始化websocket，并订阅在线主播数据变化通知
            const livers = this.livers
            const ids = this.liverIds

            this.$io.on('live:update', result => {
                if (window['__debug__']) {
                    console.log('update live:', result)
                }

                livers.splice(0, livers.length)
                ids.splice(0, ids.length)

                livers.push(...result)
                ids.push(...this._.pluck(livers, 'id'))

                this.$store.dispatch('update_lives', livers)
                
                this.makeMarkers()
            })
        },
        initMap() {
            //定义地图中心点坐标
            const center = new TMap.LatLng(25.43197588002793, 101.73899656599349)
            //定义map变量，调用 TMap.Map() 构造函数创建地图
            this.map = new TMap.Map(this.$refs.tx_map_container, {
                center: center,//设置地图中心点坐标
                mapStyleId: 'style2',
                zoom: 5.0,   //设置地图缩放级别
                pitch: 43.5,  //设置俯仰角,43.5
                viewMode: '3D',
                rotation: 0,    //设置地图旋转角度
                baseMap: { type: 'satellite' }//vector
            }).on('zoom', () => {
                const zoom = this.map.getZoom()
                if (this.label && zoom < 10) {
                    this.clearLabel()
                }
                if (!this.label && zoom > 10) {
                    this.makeLabels()
                }
            })

            this.infoWindow = new TMap.InfoWindow({
                map: this.map,
                position: new TMap.LatLng(0, 0),
                offset: {x: 0, y: -32}
            })

            window.infoWindow = this.infoWindow
        },
        updateMap(conf) {
            const map = this.map
            const center = new TMap.LatLng(conf['center']['lat'], conf['center']['lng'])
            map.setCenter(center)
            map.setPitch(conf['pitch'])
            map.setZoom(conf['zoom'])
            map.setRotation(conf['rotation'])
            map.setViewMode(conf['viewMode'])
            map.setBaseMap({type: conf['baseMap']})
            map.setMapStyleId(conf['styleId'])

            this.makeMarkers()
        },
        //加载博主位置数据，并在地图上标记marker
        async loadAnchormans() {
            const rlt = await loadAnchorman()
            if (rlt['status'] != -1) {
                Message(`加载博主数据错误，稍后按F5键刷新重试！${rlt['err']}`, {
                    type: 'error'
                })
                return
            }

            this.ams = rlt['content']
        },
        async makeMarkers() {
            const markers = []
            const ids = this.liverIds
            const mapConf = this.map_conf
            
            const resolveIcon = function (item){
                let i = item['gender'] === 1 ? 'stl1' : 'stl2'

                if (!mapConf['hideLive'] && ids.includes(item['dyid'])){
                    i += 'l'
                }

                return i
            }

            //拼装博主列表数据，生成地图marker、label格式数据
            this.ams.forEach((i, idx) => {
                markers.push({ // 点数组
                    id: i['id'],
                    position: new TMap.LatLng(i['lat'], i['lng']),
                    styleId: resolveIcon(i),
                    properties: { 'idx': idx }
                })
            })

            new TMap.MultiMarker({
                map: this.map,
                styles: styles,
                geometries: markers
            }).on('click', async (e) => {
                this.currentMarker = this.ams[e['geometry']['properties']['idx']]
                
                await this.showAmInfo(this.currentMarker)
                // this.infoWindow.setPosition(new TMap.LatLng(e['latLng']['lat'],e['latLng']['lng']))
                // this.infoWindow.setContent(`<div style="padding: 30px;background-color: #4b67af" id="dd" onclick="infoWindow.close()">${id}</div>`)
                // this.infoWindow.open()
            })
        },
        async makeLabels() {
            const labels = []
            this.ams.forEach((i) => {
                labels.push({
                    id: `l${i['id']}`, //点图形数据的标志信息
                    styleId: "label", //样式id
                    position: new TMap.LatLng(i['lat'], i['lng']), //标注点位置
                    content: i['nickname'], //标注文本
                })
            })

            this.label = new TMap.MultiLabel({
                id: 'label-layer',
                map: this.map,
                styles: {
                    'label': new TMap.LabelStyle({
                        'color': '#010817', //颜色属性
                        'size': 20, //文字大小属性
                        'offset': {x: 0, y: -55}, //文字偏移属性单位为像素
                        'angle': 0, //文字旋转属性
                        'alignment': 'center', //文字水平对齐属性
                        'verticalAlignment': 'middle', //文字垂直对齐属性
                        'backgroundColor': '#e9f1f3',
                        'padding': '10px',
                        'borderRadius': 10,
                        'borderWidth': 1,
                        'borderColor': '#5d6261'
                    })
                },
                geometries: labels
            })
        },
        clearLabel() {
            if (this.label) {
                this.label.setMap(null)
                this.label = null
            }
        },
        async showAmInfo(amInfo) {
            this.$emit('showAmInfo', amInfo)
        }
    }
}

</script>
<style lang="scss" scoped>
@import "tMap";
</style>
